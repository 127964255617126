.defaultFont {
    font-family: 'Zen Maru Gothic', sans-serif;
    color: #fff;
    text-shadow: #95c 5px 5px 10px;
}

.cursive {
    font-family: Tangerine, cursive;
}

.rightBorderBox {
    border-right: solid #fff 1px;
}

.imgBox {
    width: 100%;
    max-width: 720px;
    margin: 16px auto;
    border: solid #fff 1px;
    box-shadow: 5px 5px 10px #95c;
}

.backgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #daf;
}

.contentInnerContainer {
    max-width: 1024px;
}

.meachi {
    min-width: 100vw;
    min-height: 100vh;
    animation-delay: 1s;
    object-fit: cover;
}

.fadeIn {
    opacity: 0;
    animation-name: fadeInAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnime {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
