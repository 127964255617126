.linkButton {
    display: block;
    width: auto;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s;
}

.linkBorderButton {
    border: 1px solid #fff;
    border-radius: 0;
    box-shadow: 4px 4px 0 #fff;
}

.linkBorderButton:hover {
    box-shadow: 0 0 0 #fff;
    transform: translate(4px, 4px);
}
